/* eslint-disable react/display-name */
import { FC, PropsWithChildren, ReactNode } from 'react';

import { documentToReactComponents, type Options } from '@contentful/rich-text-react-renderer';
import { Document, INLINES, Text, BLOCKS, Node, NodeData } from '@contentful/rich-text-types';
import { useTheme } from '@mui/material/styles';
import { Button } from '@vakantiesnl/components/src/__LEGACY__/atoms/Button';
import { HeadingWithTick } from '@vakantiesnl/components/src/__LEGACY__/atoms/HeadingWithTick';
import { LinkCard } from '@vakantiesnl/components/src/__LEGACY__/atoms/LinkCard';
import { Row } from '@vakantiesnl/components/src/__LEGACY__/atoms/Row';
import { SimpleTable } from '@vakantiesnl/components/src/__LEGACY__/atoms/SimpleTable';
import { Span } from '@vakantiesnl/components/src/__LEGACY__/atoms/Span';
import { DownloadImage } from '@vakantiesnl/components/src/__LEGACY__/molecules/DownloadImage';
import { ImageGrid } from '@vakantiesnl/components/src/__LEGACY__/molecules/ImageGrid';
import { Alert } from '@vakantiesnl/components/src/atoms/Alert';
import { Button as NewButton } from '@vakantiesnl/components/src/atoms/Button';
import { Link } from '@vakantiesnl/components/src/atoms/Link';
import { Typography, TypographyProps } from '@vakantiesnl/components/src/atoms/Typography';
import { UnorderedList } from '@vakantiesnl/components/src/atoms/UnorderedList';
import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import {
	mapAlertElement,
	mapHeaderWithTickList,
	mapSpanElement,
	mapUnorderedList,
	mapSimpleTableElement,
	mapButtonRow,
	mapImageGrid,
	mapImageCard,
	mapButtonElement,
	getTarget,
	mapNewButtonElement,
} from '@vakantiesnl/services/src/contentful/mapper';
import {
	RawAlertElement,
	RawUnorderedList,
	RawSimpleTable,
	RawSpanElement,
	RawButtonRow,
	RawImageGrid,
	RawImageCard,
	RawButtonElement,
	RawNewButtonElement,
} from '@vakantiesnl/types';
import { Entry } from 'contentful';

import useStyles from './RichtText.styles';
import { returnValidUrl } from '../../utils/linkUtils';
import { useBreakpoints } from '../../utils/useBreakpoints';

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

type RichtTextProps = {
	document: Document;
	styling?: RichTextElementStyling;
} & WrapperProps;

interface ImageWrapperProps {
	fileData: NodeData;
	wrapperStyling?: string;
}

type WrapperProps = {
	wrapperStyling?: string;
};

interface TypographyWrapperProps extends TypographyProps, WrapperProps {}

const ImageWrapper: FC<ImageWrapperProps> = ({ fileData, wrapperStyling }) => {
	const { classes, cx } = useStyles();
	const theme = useTheme();

	return (
		<div className={cx(classes.imageContainer, wrapperStyling)}>
			<ImageComponent
				useLoader="Contentful"
				contentfulParams={contentfulParams}
				className={classes.image}
				src={fileData.url}
				alt={fileData.alt ? fileData.alt : ''}
				title={fileData.title}
				width={fileData.details.image.width}
				height={fileData.details.image.height}
				/** Has to support embedded full-width sized images on content pages, which is 1168 px.
				 * For smaller screens than that, be responsive to the viewport. */
				sizes={`(max-width:${theme.breakpoints.values.lg}px) 100vw, 1168px`}
			/>
		</div>
	);
};

const ParagraphWrapper: FC<PropsWithChildren<WrapperProps>> = ({ children, wrapperStyling }) => {
	const { classes, cx } = useStyles();
	return (
		<Typography variant="bodyMd" as="p" className={cx(classes.paragraph, wrapperStyling)}>
			{children}
		</Typography>
	);
};

const TypographyWrapper: FC<PropsWithChildren<TypographyWrapperProps>> = ({ children, wrapperStyling, ...props }) => {
	const { classes, cx } = useStyles();
	return (
		<Typography {...props} className={cx(classes.heading, wrapperStyling)}>
			{children}
		</Typography>
	);
};

const TableWrapper: FC<PropsWithChildren<WrapperProps>> = ({ children, wrapperStyling }) => {
	const { classes, cx } = useStyles();
	return (
		<table className={cx(classes.contentfulTable, wrapperStyling)}>
			<tbody>{children}</tbody>
		</table>
	);
};

export interface RichTextElementStyling {
	[BLOCKS.EMBEDDED_ENTRY]?: string; // currently only handles 'button' sub-type.
	[BLOCKS.HEADING_1]?: string;
	[BLOCKS.HEADING_2]?: string;
	[BLOCKS.HEADING_3]?: string;
	[BLOCKS.HEADING_4]?: string;
	[BLOCKS.HEADING_5]?: string;
	[BLOCKS.PARAGRAPH]?: string;
	[BLOCKS.UL_LIST]?: string;
}

const options = (
	styling?: RichTextElementStyling,
	wrapperStyling?: string,
	isDesktop?: boolean,
): { renderNode: Options['renderNode'] } => ({
	renderNode: {
		[BLOCKS.EMBEDDED_ENTRY]: (node: Node): ReactNode => {
			if (node.data !== undefined && node.data.target.sys.contentType !== undefined) {
				const contentType = node.data.target.sys.contentType.sys.id;
				switch (contentType) {
					case 'alertAtom':
						const alertAtomElement = node.data.target as Entry<RawAlertElement>;
						const fields = mapAlertElement(alertAtomElement);
						if (fields.description) {
							return (
								<Alert
									title={fields?.title}
									hyperlink={fields.hyperlink}
									variant={fields.variant}
									text={fields.description}
								/>
							);
						}

					case 'span':
						const spanElement = node.data.target as Entry<RawSpanElement>;
						return <Span title={mapSpanElement(spanElement).title} />;
					case 'headerWithTick':
						return mapHeaderWithTickList(node.data.target).map((item, key) => (
							<HeadingWithTick key={key} title={item} />
						));
					case 'simpleTable': // To be removed on the 1st of april 2024
						const simpleTableElement = node.data.target as Entry<RawSimpleTable>;
						return (
							<SimpleTable
								key={simpleTableElement.sys.id}
								table={mapSimpleTableElement(simpleTableElement).table}
							/>
						);
					case 'buttonRow':
						const buttonRowElement = node.data.target as Entry<RawButtonRow>;
						return (
							<Row key={buttonRowElement.sys.id}>
								{mapButtonRow(buttonRowElement).buttons.map((button, key) => {
									return button.link ? (
										<Link
											href={button.link}
											size="medium"
											className={styling?.[BLOCKS.EMBEDDED_ENTRY]}
										>
											<NewButton key={key} asText>
												{button.title}
											</NewButton>
										</Link>
									) : (
										// Keep the old Button for other cases to support the variants that can be set for a Button in contentful
										// Since variants of the new Button don't match those in Contentful
										<Button
											key={key}
											variant={button.variant}
											title={button.title}
											link={button.link}
										/>
									);
								})}
							</Row>
						);
					case 'imageGrid':
						const imageGridElement = node.data.target as Entry<RawImageGrid>;
						return (
							<ImageGrid key={imageGridElement.sys.id}>
								{mapImageGrid(imageGridElement).imageCards.map((image, key) => (
									<LinkCard
										key={key}
										title={image.buttonTitle}
										imgSrc={image.imageSrc}
										slug={image.link}
										target={image.target}
									/>
								))}
							</ImageGrid>
						);
					case 'imageCard':
						const imageCardElement = node.data.target as Entry<RawImageCard>;
						const imageCard = mapImageCard(imageCardElement.fields);

						return (
							<LinkCard
								key={imageCardElement.sys.id}
								title={imageCard.buttonTitle}
								imgSrc={imageCard.imageSrc}
								slug={imageCard.link}
								variant={imageCard.variant}
								target={imageCard.target}
							/>
						);
					case 'button':
						const buttonElement = node.data.target as Entry<RawButtonElement>;
						const button = mapButtonElement(buttonElement.fields);
						return (
							<Button
								title={button.title}
								link={button.link}
								variant={button.variant}
								icon={button.iconType}
								className={styling?.[BLOCKS.EMBEDDED_ENTRY]}
							/>
						);
				}
			}
		},
		[BLOCKS.HEADING_1]: (_node: Node, children: ReactNode): JSX.Element => (
			<TypographyWrapper wrapperStyling={wrapperStyling} variant="heading2Xl" as="h1">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_2]: (_node: Node, children: ReactNode): JSX.Element => (
			<TypographyWrapper wrapperStyling={wrapperStyling} variant="headingXl" as="h2">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_3]: (_node: Node, children: ReactNode): JSX.Element => (
			<TypographyWrapper wrapperStyling={wrapperStyling} variant="headingLg" as="h3">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_4]: (_node: Node, children: ReactNode): JSX.Element => (
			<TypographyWrapper wrapperStyling={wrapperStyling} variant="headingMd" as="h4">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_5]: (_node: Node, children: ReactNode): JSX.Element => (
			<TypographyWrapper wrapperStyling={wrapperStyling} variant="headingSm" as="h5">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.PARAGRAPH]: (_node: Node, children: ReactNode): ReactNode => (
			<ParagraphWrapper wrapperStyling={wrapperStyling}>{children}</ParagraphWrapper>
		),
		[BLOCKS.UL_LIST]: (node: Node): JSX.Element => {
			return (
				<UnorderedList
					items={mapUnorderedList(node as RawUnorderedList)}
					containsRichText
					customClassName={styling?.[BLOCKS.UL_LIST]}
				/>
			);
		},

		[BLOCKS.TABLE]: (_node: Node, children: ReactNode): JSX.Element => {
			return <TableWrapper wrapperStyling={wrapperStyling}>{children}</TableWrapper>;
		},

		[BLOCKS.EMBEDDED_ASSET]: (node: Node): JSX.Element => {
			const data = node.data?.fields || node.data?.target?.fields;

			if (data) {
				const { file } = data;

				return <ImageWrapper wrapperStyling={wrapperStyling} fileData={file} />;
			}
			return <></>;
		},

		[INLINES.HYPERLINK]: (node: Node): JSX.Element => {
			const document = node as Document;
			const hyperLink = document.content as Node[];
			const hyperlinkTextArray = hyperLink as Text[];

			return (
				<Link href={returnValidUrl(document.data.uri)} size="medium" target={getTarget(document.data.uri)}>
					{hyperlinkTextArray.map((hyperLinkText) => hyperLinkText.value)}
				</Link>
			);
		},
		[INLINES.ENTRY_HYPERLINK]: (node: Node): JSX.Element => {
			const document = node as Document;
			const hyperLink = document.content as Node[];
			const hyperlinkTextArray = hyperLink as Text[];
			const hyperLinkHref = document.data.target.fields.link
				? document.data.target.fields.link
				: document.data.target.fields.seo?.fields.slug;

			return (
				<Link href={returnValidUrl(hyperLinkHref)} size="medium" target={getTarget(hyperLinkHref)}>
					{hyperlinkTextArray.map((hyperLinkText) => hyperLinkText.value)}
				</Link>
			);
		},
		[INLINES.ASSET_HYPERLINK]: (node: Node): JSX.Element => {
			const data = node.data.target.fields.file;
			const { fileName, url } = data;
			return <DownloadImage nameIcon={fileName} pathIcon={url} />;
		},
		[INLINES.EMBEDDED_ENTRY]: (node: Node): ReactNode => {
			if (node.data !== undefined && node.data.target.sys.contentType !== undefined) {
				const contentType = node.data.target.sys.contentType.sys.id;
				switch (contentType) {
					case 'button':
						const buttonElement = node.data.target as Entry<RawNewButtonElement>;
						const button = mapNewButtonElement(buttonElement.fields);

						return (
							<Link
								href={button.link}
								target={button.trailingIcon === 'external' ? '_blank' : undefined}
								className={styling?.[BLOCKS.EMBEDDED_ENTRY]}
							>
								<NewButton
									variant={button.variant}
									size={isDesktop ? 'medium' : 'small'}
									trailingIcon={button.trailingIcon}
									asText
								>
									{button.title}
								</NewButton>
							</Link>
						);
				}
			}
		},
	},
});

export const RichText: FC<RichtTextProps> = ({ document, styling, wrapperStyling }) => {
	const { isDesktop } = useBreakpoints();

	return <>{documentToReactComponents(document, options(styling, wrapperStyling, isDesktop))}</>;
};
