import { buildBrandedSearchRelatedCardUrlParams } from '@vakantiesnl/services/src/util/urlUtils';
import { LinkCard } from '@vakantiesnl/types/src';
import { useRouter } from 'next/router';

export function useLinkCardUrl(linkCard: LinkCard, departureDate?: string[] | null): string {
	const router = useRouter();
	const { slug, queryParams } = linkCard;
	const cardQueryParams = buildBrandedSearchRelatedCardUrlParams(queryParams || '', router.query, departureDate);

	return `${slug}${cardQueryParams ? cardQueryParams : ''}`;
}
