import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = makeStyles()((theme) => ({
	imageContainer: {
		width: '100%',
		height: 'auto',
	},
	image: {
		height: 'auto',
		width: '100%',
		objectFit: 'contain',
	},
	heading: {
		marginBottom: theme.spacing.small,
	},
	paragraph: {
		marginBottom: theme.spacing.small,
	},
	contentfulTable: {
		borderSpacing: 0,
		display: 'block',
		marginBottom: theme.spacing.medium,
		tbody: { display: 'block' },
		tr: {
			borderBottom: theme.table.border,
			display: 'block',
			padding: `${theme.spacing.small} 0`,
		},
		th: {
			font: theme.table.cell.head.font,
			textAlign: 'left',
			p: {
				font: theme.table.cell.head.font, // Contentful adds paragraphs inside the tables automatically
				marginBottom: 0,
			},
			'&:first-of-type': {
				minWidth: '175px', // No design token for this one
			},
		},
		td: {
			font: theme.table.cell.default.font,
			p: {
				font: theme.table.cell.default.font, // Contentful adds paragraphs inside the tables automatically
				marginBottom: 0,
			},
			'&:first-of-type': {
				color: theme.body.foreground.color.dimmed,
				minWidth: '175px', // No design token for this one
				p: {
					color: theme.body.foreground.color.dimmed,
				},
			},
		},
		['th, td']: {
			paddingRight: theme.spacing.medium,

			'&:last-of-type': {
				paddingRight: 0,
			},
		},
	},
}));

export default useStyles;
