import { LinkItemProps } from '@vakantiesnl/components/src/molecules/LinkList/LinkList';
import { MenuLink, ElementNavItem, Link } from '@vakantiesnl/types';

export function isMenuLink(menuItem: MenuLink | ElementNavItem): menuItem is MenuLink {
	return (menuItem as MenuLink).title !== undefined && (menuItem as MenuLink).link !== undefined;
}

export const createLinks = (userMenu: ElementNavItem | null): LinkItemProps[] => {
	if (!userMenu || !isElementNavItem(userMenu) || !userMenu.navigationItems) {
		// eslint-disable-next-line no-console
		console.error('Error: Conditions not satisfied for creating links');
		return [];
	}
	const mappedLinks = linksMapper(userMenu);

	if (mappedLinks === undefined) {
		// eslint-disable-next-line no-console
		console.error('Error: linksMapper returned undefined');
		return [];
	}

	return mappedLinks;
};

export function isElementNavItem(item: MenuLink | ElementNavItem): item is ElementNavItem {
	return 'listTitle' in item;
}

export function linksMapper(userMenu: ElementNavItem): LinkItemProps[] | undefined {
	if (userMenu && isElementNavItem(userMenu) && userMenu?.navigationItems) {
		return userMenu.navigationItems.map(
			(item): LinkItemProps => ({
				text: item.title,
				href: (item as Link).link,
				icon: (item as Link).iconType,
			}),
		);
	}
}
